<template>
  <section>
    <div class="content-header">
      <h2>“What I Learned from 100 Days of Rejection”</h2>
    </div>
    <div class="content-wrapper">
      <p>In this riveting Ted Talk, CEO and public speaker, Jia Jiang “adventures boldly into a territory so many of us fear: rejection. By seeking out rejection for 100 days—from asking a stranger to borrow $100 to requesting a ‘burger refill’ at a restaurant—Jiang desensitized himself to the pain and shame that rejection often brings and, in the process, discovered that simply asking for what you want can open up possibilities where you expect to find dead ends.”</p>
      <p>Share the <a href="https://www.ted.com/talks/jia_jiang_what_i_learned_from_100_days_of_rejection" target="_blank">“What I Learned from 100 Days of Rejection”</a>  Ted Talk with students to prepare them for—and help them see the opportunities that can come along with—rejection.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '11',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
